<template>
    <div class="container">

        <!-- Van Checks -->
        <div class="title">
            <h2>Before setting off in the morning</h2>
            <h5 class="text-subtitle">3 of 6 tasks complete</h5>
        </div>
        <label>Daily van checks</label>
        <!-- Checklist -->
        <ul class="list-group selection-list mb-5">
            <li class="list-group-item" :class="{'active': checkDamage}">
                <label class="custom-control custom-checkbox mb-0">
                    <input type="checkbox" class="custom-control-input" id="check-damage" v-model="checkDamage">
                    <label class="custom-control-label mb-0" for="check-damage">Check for any damage</label>
                </label>
            </li>
            <li class="list-group-item" :class="{'active': checkTyres}">
                <label class="custom-control custom-checkbox mb-0">
                    <input type="checkbox" class="custom-control-input" id="check-tyres" v-model="checkTyres">
                    <label class="custom-control-label mb-0" for="check-tyres">Check tyres for tread, pressure and any damage</label>
                </label>
            </li>
            <li class="list-group-item" :class="{'active': checkFluid}">
                <label class="custom-control custom-checkbox mb-0">
                    <input type="checkbox" class="custom-control-input" id="check-fluid" v-model="checkFluid">
                    <label class="custom-control-label mb-0" for="check-fluid">Check oil and other fluid levels</label>
                </label>
            </li>
            <li class="list-group-item" :class="{'active': checkMirrors}">
                <label class="custom-control custom-checkbox mb-0">
                    <input type="checkbox" class="custom-control-input" id="check-mirrors" v-model="checkMirrors">
                    <label class="custom-control-label mb-0" for="check-mirrors">Check mirrors are set correctly and clean</label>
                </label>
            </li>
            <li class="list-group-item" :class="{'active': checkLights}">
                <label class="custom-control custom-checkbox mb-0">
                    <input type="checkbox" class="custom-control-input" id="check-lights" v-model="checkLights">
                    <label class="custom-control-label mb-0" for="check-lights">Check all lights are working</label>
                </label>
            </li>
            <li class="list-group-item" :class="{'active': checkWarnings}">
                <label class="custom-control custom-checkbox mb-0">
                    <input type="checkbox" class="custom-control-input" id="check-warnings" v-model="checkWarnings">
                    <label class="custom-control-label mb-0" for="check-warnings">Check dash for any warnings, lights etc.</label>
                </label>
            </li>
        </ul>

        <!-- End of Day -->
        <div class="title">
            <h2>End of day tasks</h2>
            <h5 class="text-subtitle">0 of 5 tasks complete</h5>
        </div>
        <label>At the end of the day it is important to make sure that you are prepared for the next day of work</label>
        <!-- Checklist -->
        <ul class="list-group selection-list mb-4">
            <li class="list-group-item" :class="{'active': tidyVan}">
                <label class="custom-control custom-checkbox mb-0">
                    <input type="checkbox" class="custom-control-input" id="tidy-van" v-model="tidyVan">
                    <label class="custom-control-label mb-0" for="tidy-van">After completing your last job tidy away any tools and rubbish in the back of the van</label>
                </label>
            </li>
            <li class="list-group-item" :class="{'active': esdCleaning}">
                <label class="custom-control custom-checkbox mb-0">
                    <input type="checkbox" class="custom-control-input" id="esd-cleaning" v-model="esdCleaning">
                    <label class="custom-control-label mb-0" for="esd-cleaning">Follow the ESD cleaning procedure and log it on your ESD cleaning log</label>
                </label>
            </li>
            <li class="list-group-item" :class="{'active': checkTomorrowsJobs}">
                <label class="custom-control custom-checkbox mb-0">
                    <input type="checkbox" class="custom-control-input" id="check-tomorrows-jobs" v-model="checkTomorrowsJobs">
                    <label class="custom-control-label mb-0" for="check-tomorrows-jobs">Check tomorrow's jobs and ensure that for any IW repairs the software is up to date on fenrir</label>
                </label>
            </li>
            <li class="list-group-item" :class="{'active': turnOffVan}">
                <label class="custom-control custom-checkbox mb-0">
                    <input type="checkbox" class="custom-control-input" id="turn-off-van" v-model="turnOffVan">
                    <label class="custom-control-label mb-0" for="turn-off-van">Make sure that everything is turned off in the van so that the batteries don't drain overnight</label>
                </label>
            </li>
            <li class="list-group-item" :class="{'active': checkSvc}">
                <label class="custom-control custom-checkbox mb-0">
                    <input type="checkbox" class="custom-control-input" id="check-svc" v-model="checkSvc">
                    <label class="custom-control-label mb-0" for="check-svc">Check svc bulletin for updates</label>
                </label>
            </li>
        </ul>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                checkDamage: true,
                checkTyres: true,
                checkFluid: true,
                checkMirrors: false,
                checkLights: false,
                checkWarnings: false,

                tidyVan: false,
                esdCleaning: false,
                checkTomorrowsJobs: false,
                turnOffVan: false,
                checkSvc: false,
            }
        }
    }
</script>

<style scoped>

</style>